<template>
  <div class="utility-page-wrap">
    <div class="container">
      <div class="two-col-grid">
        <div class="utility-page-content">
          <h1 class="h2 _404-page">No se encontro lo que buscabas</h1>
          <router-link to="/" class="primary-small-button back-button">
            VOLVER
          </router-link>
        </div>
        <div class="image-error-page">
          <img src="images/404.png" loading="lazy" alt="no encontrado" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notfound",
};
</script>
